<template>
  <a-modal
      :width="800"
      centered
      destroyOnClose
      :title="formModal.title"
      :visible="formModal.open"
      :confirm-loading="confirmLoading"
      @ok="onConfirm"
      @cancel="closeModal"
  >
    <a-spin :spinning="loading">
      <a-form-model
        ref="formRef"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="审批模板">
          <span>{{ templateData.template_name || '-' }}</span>
        </a-form-model-item>
        <a-form-model-item prop="approvaltype" label="模板类型">
          <a-radio-group v-model="form.approvaltype">
            <template v-for="item in tempType">
              <a-radio :value="item.id" :key="item.id">{{ item.name }}</a-radio>
            </template>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          prop="node"
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          label="请选择下方审批流程中的某个人或者某个节点，所选人员审批完成后将自动生成出库单"
        >
          <div :style="{ marginLeft: '12px' }">注: 或签流程请选择节点，不可选择人员。</div>
          <a-radio-group v-model="form.node">
            <template v-if="!!nodeData.length">
              <div v-for="item in nodeData" class="radio-body" :key="item.id">
                <a-radio :value="item.id">{{ item.name }}</a-radio>
                <a-divider type="vertical" />
                <div class="radio-body-process">
                  <div v-for="itm in item.users" :class="item.approverattr === 1 ? 'radio-body-process-disable' : 'radio-body-process-body'" :key="itm.user.userid">
                    <a-radio :value="itm.user.id" :disabled="item.approverattr === 1">{{ itm.user.name }}</a-radio>
                  </div>
                </div>
              </div>
            </template>
            <span v-else>暂无</span>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { tempRules } from '@/utils/maps/productDictionary'
import { templateDetail, updateTemplate } from '@/api/productionDictionary'

export default {
  name: "EditTemplate",
  data() {
    return {
      form: {},
      formModal: {},
      templateData: {},
      tempType: [],
      nodeData: [],
      loading: false,
      confirmLoading: false,
      rules: tempRules,
    }
  },
  methods: {
    // 处理单选ID相同
    handleOnlyId(param) {
      if (param) {
        const list = JSON.parse(JSON.stringify(param))
        list.forEach(item => {
          item.users.forEach(itm => {
            itm.user.id = `${item.id}.${itm.user.id}`
          })
        })
        this.nodeData = list
      }
    },
    getTemp(template_id) {
      templateDetail({ template_id }).then(data => {
        const { results = {} } = data
        this.templateData = results[0]
        this.tempType = this.templateData?.info?.type_list
        this.handleOnlyId(this.templateData?.info?.process?.steps)
        // 已选节点赋值
        if (this.templateData?.approvaltype) {
          this.form = { ...this.form, approvaltype: this.templateData.approvaltype }
        }
        if (this.templateData?.step) {
          if (this.templateData?.user) {
            const node = `${this.templateData.step}.${this.templateData.user}`
            this.form = { ...this.form, node }
          } else {
            this.form = { ...this.form, node: this.templateData.step }
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    editModal(record) {
      this.formModal = {
        open: true,
        title: '编辑模板',
        type: 'edit',
      }
      this.loading = true
      this.getTemp(record)
    },
    onConfirm() {
      this.loading = true
      this.$refs.formRef.validate(error => {
        if (!error) {
          return false
        }
        switch (this.formModal.type) {
          case 'edit':
            this.edit()
            break
          default:
            break
        }
      })
    },
    edit() {
      let subForm = JSON.parse(JSON.stringify(this.form))
      const allset = String(subForm.node).split('.')
      if (allset[1]) {
        subForm = {
          ...subForm,
          step: Number(allset[0]),
          user: Number(allset[1])
        }
      } else {
        subForm = {
          ...subForm,
          step: Number(allset[0])
        }
      }
      delete subForm.node
      console.log(subForm, allset, '🚀 ~ EditTemplate edit line: 138 -----')
      updateTemplate(subForm, this.templateData.id).then(res => {
        this.$message.success('修改成功')
        this.$emit('ok')
        this.closeModal()
      }).finally(() => {
        this.loading = false
      })
    },
    closeModal() {
      this.templateData = {}
      this.nodeData = []
      this.formModal.open = false
      this.form = {}
      this.$refs.formRef.resetFields()
    },
  }
}
</script>

<style lang="less" scoped>
.radio-body:last-child {
  ///deep/ .ant-divider {
  //  display: none;
  //}
}
/deep/ .ant-divider, .ant-divider-vertical {
  height: 5em;
  margin: 4px 8px;
  position: relative;
  left: 20%;
}
.radio-body {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  &-process {
    position: absolute;
    left: 40%;
    top: 25%;
    display: flex;
    flex-wrap: wrap;
    height: 5em;
    width: 700px;
    align-items: center;
    &-body {
      border: 1px solid #ccc;
      padding: 4px 6px;
      margin-right: 10px;
      border-radius: 4px;
      display: inline;
      &:hover {
        border-color: #1890ff;
        /deep/ .ant-radio-wrapper span {
          color: #1890ff;
        }
      }
    }
    &-disable {
      border: 1px solid #ccc;
      padding: 4px 6px;
      margin-right: 10px;
      border-radius: 4px;
      display: inline;
      cursor: not-allowed;
      /deep/ .ant-radio-wrapper {
        cursor: not-allowed;
      }
    }
  }
}
</style>
